const Spinner = () => {
    return (
        // <svg xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', background: 'none', display: 'block'}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        //     <g transform="translate(80,50)">
        //     <g transform="rotate(0)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="1">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.875s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.875s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(71.21320343559643,71.21320343559643)">
        //     <g transform="rotate(45)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.875">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.75s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.75s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(50,80)">
        //     <g transform="rotate(90)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.75">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.625s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.625s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(28.786796564403577,71.21320343559643)">
        //     <g transform="rotate(135)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.625">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.5s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.5s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(20,50.00000000000001)">
        //     <g transform="rotate(180)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.5">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.375s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.375s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(28.78679656440357,28.786796564403577)">
        //     <g transform="rotate(225)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.375">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.25s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.25s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(49.99999999999999,20)">
        //     <g transform="rotate(270)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.25">
        //     <animateTransform attributeName="transform" type="scale" begin="-0.125s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="-0.125s"></animate>
        //     </circle>
        //     </g>
        //     </g><g transform="translate(71.21320343559643,28.78679656440357)">
        //     <g transform="rotate(315)">
        //     <circle cx="0" cy="0" r="7" fill="#1c4595" fillOpacity="0.125">
        //     <animateTransform attributeName="transform" type="scale" begin="0s" values="1.5 1.5;1 1" keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
        //     <animate attributeName="fillOpacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0" begin="0s"></animate>
        //     </circle>
        //     </g>
        //     </g>
        // </svg>

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        version="1"
        viewBox="0 0 128 128"
      >
        <rect width="100%" height="100%" fill="#FFF"></rect>
        <g>
          <path d="M71 39.2V.4a63.6 63.6 0 0133.96 14.57L77.68 42.24a25.53 25.53 0 00-6.7-3.03z"></path>
          <path
            fill="#e1e1e1"
            d="M86.486 51.413l27.436-27.435a63.6 63.6 0 0113.71 34.316l-38.572-.007a25.53 25.53 0 00-2.595-6.88zM88.8 71h38.8a63.6 63.6 0 01-14.57 33.96L85.76 77.68a25.53 25.53 0 003.03-6.7zM76.587 86.486l27.435 27.436a63.6 63.6 0 01-34.316 13.71l.007-38.572a25.53 25.53 0 006.88-2.595z"
          ></path>
          <path
            fill="#bebebe"
            d="M57 88.8v38.8a63.6 63.6 0 01-33.96-14.57l27.28-27.27a25.53 25.53 0 006.7 3.03z"
          ></path>
          <path
            fill="#979797"
            d="M41.514 76.587l-27.436 27.435A63.6 63.6 0 01.368 69.706l38.572.007a25.53 25.53 0 002.595 6.88z"
          ></path>
          <path
            fill="#6e6e6e"
            d="M39.2 57H.4a63.6 63.6 0 0114.57-33.96l27.27 27.28a25.53 25.53 0 00-3.03 6.7z"
          ></path>
          <path
            fill="#3c3c3c"
            d="M51.413 41.514L23.978 14.078A63.6 63.6 0 0158.294.368l-.007 38.572a25.53 25.53 0 00-6.88 2.595z"
          ></path>
          <animateTransform
            attributeName="transform"
            calcMode="discrete"
            dur="720ms"
            repeatCount="indefinite"
            type="rotate"
            values="0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64"
          ></animateTransform>
        </g>
        <g>
          <circle cx="63.66" cy="63.16" r="12"></circle>
          <animate
            attributeName="opacity"
            begin="0s"
            dur="720ms"
            keyTimes="0;0.5;1"
            repeatCount="indefinite"
            values="1;0;1"
          ></animate>
        </g>
      </svg>
    )
}

export default Spinner;